export const enum Approaches {
    RetrieveThenRead = "rtr",
    ReadRetrieveRead = "rrr",
    ReadDecomposeAsk = "rda"
}

export type AskRequestOverrides = {
    semanticRanker?: boolean;
    semanticCaptions?: boolean;
    excludeCategory?: string;
    top?: number;
    temperature?: number;
    promptTemplate?: string;
    promptTemplatePrefix?: string;
    promptTemplateSuffix?: string;
    suggestFollowupQuestions?: boolean;
    gptmodel?: string;
};

export type ChatRequestOverrides = {
    persona?: string | undefined;
    temperature?: number | undefined;
    semanticRanker?: boolean;
    semanticCaptions?: boolean;
    excludeCategory?: string;
    top?: number;
    promptTemplate?: string;
    promptTemplatePrefix?: string;
    promptTemplateSuffix?: string;
    suggestFollowupQuestions?: boolean;
    gptmodel?: string;
};

export type AskRequest = {
    idp: string;
    scenario: string;
    question: string;
    approach: Approaches;
    overrides?: AskRequestOverrides;
};

export type AskResponse = {
    answer: string;
    prompt: string;
    thoughts: string | null;
    data_points: string[];
    doc_urls: string[];
    error?: string;
    message?:string;
    statusCode?:number;
};

export type ChatTurn = {
    user: string;
    bot?: string;
};

export type ChatRequest = {
    idp: string;
    scenario: string;
    history: ChatTurn[];
    approach: Approaches;
    overrides?: ChatRequestOverrides;
    prompt?: string;
};

export type ChatGPTRequest = {
    idp: string;
    history: ChatTurn[];
    overrides?: ChatRequestOverrides;
};

export type PromptsRequest = {
    idp: string;
    scenario: string;
};

export type Prompt = {
    _id: string;
    prompt: string;
};

export type RCRRequest = {
    scenario: string;
    approach: string;
    document_key: string | number;
    page_key: string | number;
    overrides?: ChatRequestOverrides;
};

export type Legislation = {
    content: string;
    rationale?: string;
};

export type RCRResponse = {
    legislations: Legislation[];
    rationale: string;
    rcr_count: string;
    error?: string;
};

export type PrescriptiveResponse = {
    legislations: Legislation[];
    error?: string;
};

export type DocumentResponse = {
    documents: string[];
    error?: string;
};

export type PageResponse = {
    page_count: number;
    error?: string;
};

import { ExampleModel } from "./components/Example";

export const defaultScenarioName = "saputoContracts";
// Sharepoint scenarios
export const spScenarios = ["contracts", "company_corporate_policies"];

export const options = [
    { key: "saputoContracts", text: "Contracts" },
    { key: "saputoCompanyPolicies", text: "Company Corporate Policies" }
];

const data = [
    {
        scenario: "saputoContracts",
        headerTitle: "Contracts",
        exampleList: [
            {
                text: "What is the rate paid for Power BI Intermediate Developer in the SDKTek - Work Order Farrmer Portal March 2022 document?",
                value: "What is the rate paid for Power BI Intermediate Developer in the SDKTek - Work Order Farrmer Portal March 2022 document?"
            },
            {
                text: "Summarize the document 'Canto - Saputo Auto Renewal'",
                value: "Summarize the document 'Canto - Saputo Auto Renewal'"
            },
            {
                text: "How does the agreement address the use of Third-Party Integrations in connection with the Services?",
                value: "How does the agreement address the use of Third-Party Integrations in connection with the Services?"
            }
        ],
        exampleQuestionPlaceholder: "Send a message",
        backendScenario: "contracts"
    },
    {
        scenario: "saputoCompanyPolicies",
        headerTitle: "Company Corporate Policies",
        exampleList: [
            {
                text: "What actions will result in the removal of access to Saputo's network?",
                value: "What actions will result in the removal of access to Saputo's network?"
            },
            {
                text: "What are some of Saputo's achievements in animal welfare?",
                value: "What are some of Saputo's achievements in animal welfare?"
            },
            {
                text: "Who are the mandatory participants in the Emergency Change Advisory Board (ECAB)?",
                value: "Who are the mandatory participants in the Emergency Change Advisory Board (ECAB)?"
            }
        ],
        exampleQuestionPlaceholder: "Send a message",
        backendScenario: "company_corporate_policies"
    }
];

export const personas = [
    { key: "default", text: "Default", description: "The Default persona interacts with users in a neutral and inclusive manner." },
    { key: "plain_english", text: "Plain English", description: "The Plain English persona explains concepts in plain english that's easy to understand." },
    { key: "domain_expert", text: "Domain Expert", description: "The Domain Expert persona explains concepts in depth using domain-specific language." }
];

export const temperatures = [
    {
        key: 0.7,
        text: "Balanced",
        description: "The Balanced temperature generates responses that have some variation while maintaining a reasonable level of logical consistency."
    },
    { key: 0.8, text: "Creative", description: "The Creative temperature generates responses that are more diverse and unpredictable." },
    { key: 0.2, text: "Precise", description: "The Precise temperature generates responses that are more deterministic and predictable." }
];

export const gptModels = [
    {
        key: "gpt-4",
        text: "GPT 4",
        description: "Training Data: Up to Dec 2023."
    },
    {
        key: "gpt-35-turbo",
        text: "GPT 3.5 Turbo",
        description: "Training Data: Up to Sep 2021."
    }
];

export const getScenario = (scenario: string): string => {
    return scenario;
};

export const getHeaderTitle = (scenario: string): string => {
    const index = getIndex(scenario);
    return data[index].headerTitle;
};

export const getExampleList = (scenario: string): ExampleModel[] => {
    const index = getIndex(scenario);
    return data[index].exampleList;
};

export const getExampleQuestionPlaceholder = (scenario: string): string => {
    const index = getIndex(scenario);
    return data[index].exampleQuestionPlaceholder;
};

export const getBackendScenario = (scenario: string): string => {
    const index = getIndex(scenario);
    return data[index].backendScenario;
};

// Helper function: get the index of the scenario in data
const getIndex = (scenario: string): number => {
    const index = data.findIndex(item => item.scenario === scenario);
    return index;
};

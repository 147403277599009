import { useState, useEffect } from "react";
import { Pivot, PivotItem } from "@fluentui/react";
import DOMPurify from "dompurify";
import { SupportingContent } from "../SupportingContent";
import { AskResponse, readCitationURL } from "../../api";
import { AnalysisPanelTabs } from "./AnalysisPanelTabs";
import { spScenarios } from "../../content";

interface Props {
    className: string;
    activeTab: AnalysisPanelTabs;
    onActiveTabChanged: (tab: AnalysisPanelTabs) => void;
    activeCitation: string | undefined;
    citationHeight: string;
    answer: AskResponse;
    idp: string;
    selectedScenario: string;
}

const pivotItemDisabledStyle = { disabled: true, style: { color: "grey" } };

export const AnalysisPanel = ({ answer, activeTab, activeCitation, citationHeight, className, onActiveTabChanged, idp, selectedScenario }: Props) => {
    const [citationURL, setCitationURL] = useState("");
    const [openTab, setOpenTab] = useState(false);
    const isDisabledSupportingContentTab: boolean = !answer.data_points.length;

    const fetchData = async () => {
        if (activeCitation) {
            const isSharePointSource = spScenarios.includes(selectedScenario) ? true : false;
            const activationURL = new URL(activeCitation);
            const fileName = new URLSearchParams(activationURL.search).get("filename");
            const fileNameParts = fileName?.split(".");
            const fileExtension = fileNameParts !== undefined ? fileNameParts[fileNameParts.length - 1] : "";
            const isSharepointCitationNonPdf = isSharePointSource && fileExtension !== "pdf";
            if (isSharepointCitationNonPdf) setOpenTab(true);
            const newActiveCitation = activationURL + "&openLinkInNewTab=" + openTab;
            readCitationURL(newActiveCitation, idp, isSharePointSource, openTab).then(res => {
                setCitationURL(res);
                if (openTab) {
                    var newUrl = res.split("https://")[1];
                    window.open("http://" + newUrl);
                }
            });
        }
    };

    useEffect(() => {
        fetchData();
    }, [openTab, activeCitation]);

    return (
        <Pivot
            className={className}
            selectedKey={activeTab}
            onLinkClick={pivotItem => pivotItem && onActiveTabChanged(pivotItem.props.itemKey! as AnalysisPanelTabs)}
        >
            <PivotItem
                itemKey={AnalysisPanelTabs.SupportingContentTab}
                headerText="Supporting content"
                headerButtonProps={isDisabledSupportingContentTab ? pivotItemDisabledStyle : undefined}
            >
                <SupportingContent supportingContent={answer.data_points} />
            </PivotItem>
        </Pivot>
    );
};

import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import SaputoWhite from "../assets/saputo-logo-white.png";
import SaputoRed from "../assets/saputo-logo-red.png";
import { useGlobalContext } from "../GlobalContext";

export const logoDict: Record<string, string> = {
    default: SaputoWhite,
    saputo: SaputoRed
};

// Using query params to dynamically change the logo
export const useQueryParamLogo = () => {
    const { setLogo, setIsDefaultLogo } = useGlobalContext();
    const location = useLocation();

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const logoId = params.get("logo");

        if (logoId && logoId in logoDict) {
            setLogo(logoDict[logoId]);
            setIsDefaultLogo(false);
        } else {
            setLogo(logoDict.default);
            setIsDefaultLogo(true);
        }
    }, [location.search]);
};

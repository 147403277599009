import { useRef, useState, useEffect } from "react";
import { Panel, DefaultButton, Text } from "@fluentui/react";
import styles from "./ChatGPT.module.css";
import robot from "../../assets/saputo-robot-black.svg";
import { chatGPTApi, AskResponse, ChatGPTRequest, ChatTurn } from "../../api";
import { AnswerChatGPT, AnswerError, AnswerLoading } from "../../components/Answer";
import { QuestionInput } from "../../components/QuestionInput";
import { UserChatMessage } from "../../components/UserChatMessage";
import { ClearChatButton } from "../../components/ClearChatButton";
import { ScrollButtons } from "../../components/ScrollButtons";
import { TemperatureSelector } from "../../components/ConfigSelector/TemperatureSelector";
import { GptModelSelector } from "../../components/ConfigSelector/GptModelSelector";
import { SettingsButton } from "../../components/SettingsButton";
import { gptModels, temperatures } from "../../content";
import { ButtonGroup } from "../../components/ButtonGroup/ButtonGroup";

type Props = {
    idp: string;
};
interface SelectedTemperature {
    name: number | undefined;
    description: string | undefined;
}
interface selectedGptModel {
    name: string | undefined;
    description: string | undefined;
}

const ChatGPT = ({ idp }: Props) => {
    const [isConfigPanelOpen, setIsConfigPanelOpen] = useState(false);
    const lastQuestionRef = useRef<string>("");
    const chatMessageStreamEnd = useRef<HTMLDivElement | null>(null);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<unknown>();

    const [answers, setAnswers] = useState<[user: string, response: AskResponse][]>([]);

    const [selectedTemperature, setSelectedTemperature] = useState<SelectedTemperature>({
        name: temperatures[0].key,
        description: temperatures[0].description
    });

    const [selectedGptModel, setSelectedGptModel] = useState<selectedGptModel>({
        name: gptModels[0].key,
        description: gptModels[0].description
    });

    const makeApiRequest = async (question: string) => {
        lastQuestionRef.current = question;
        error && setError(undefined);
        setIsLoading(true);

        try {
            const history: ChatTurn[] = answers.map(a => ({ user: a[0], bot: a[1].answer }));
            const request: ChatGPTRequest = {
                idp,
                history: [...history, { user: question, bot: undefined }],
                overrides: {
                    gptmodel: selectedGptModel.name,
                    temperature: selectedTemperature.name
                }
            };
            const result = await chatGPTApi(request);
            setAnswers([...answers, [question, result]]);
        } catch (e) {
            setError(e);
        } finally {
            setIsLoading(false);
        }
    };

    const clearChat = () => {
        lastQuestionRef.current = "";
        error && setError(undefined);
        setAnswers([]);
    };

    useEffect(() => chatMessageStreamEnd.current?.scrollIntoView({ behavior: "smooth" }), [isLoading]);

    return (
        <div className={styles.container}>
            <div className={styles.commandsContainer}>
                <ClearChatButton className={styles.commandButton} onClick={clearChat} disabled={!lastQuestionRef.current || isLoading} />
                <SettingsButton className={styles.commandButton} onClick={() => setIsConfigPanelOpen(!isConfigPanelOpen)} />
            </div>
            <div className={styles.chatRoot}>
                <div className={styles.chatContainer}>
                    {!lastQuestionRef.current ? (
                        <div className={styles.chatEmptyState}>
                            <div className={styles.chatEmptyStateContainer}>
                                <img src={robot} alt="Slalom robot" height="150px" />
                                <div className={styles.chatEmptyStateTitles}>
                                    <h1 className={styles.chatEmptyStateTitle}>ChatGPT</h1>
                                    <h2 className={styles.chatEmptyStateSubtitle}>Start a general conversation or ask about anything!</h2>
                                    <ButtonGroup setSelectedKey={setSelectedTemperature} />
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className={styles.chatMessageStream}>
                            {answers.map((answer, index) => (
                                <div key={index}>
                                    <UserChatMessage message={answer[0]} />
                                    <div className={styles.chatMessageGpt}>
                                        <AnswerChatGPT key={index} answer={answer[1]} />
                                    </div>
                                </div>
                            ))}
                            {isLoading && (
                                <>
                                    <UserChatMessage message={lastQuestionRef.current} />
                                    <div className={styles.chatMessageGptMinWidth}>
                                        <AnswerLoading />
                                    </div>
                                </>
                            )}
                            {error ? (
                                <>
                                    <UserChatMessage message={lastQuestionRef.current} />
                                    <div className={styles.chatMessageGptMinWidth}>
                                        <AnswerError error={error.toString()} onRetry={() => makeApiRequest(lastQuestionRef.current)} />
                                    </div>
                                </>
                            ) : null}
                            <div ref={chatMessageStreamEnd} />
                        </div>
                    )}

                    <div className={styles.chatInput}>
                        <QuestionInput clearOnSend placeholder="Send a message" disabled={isLoading} onSend={question => makeApiRequest(question)} />
                    </div>
                </div>

                <Panel
                    headerText="Configure answer generation"
                    isOpen={isConfigPanelOpen}
                    isBlocking={false}
                    onDismiss={() => {
                        setIsConfigPanelOpen(false);
                    }}
                    closeButtonAriaLabel="Close"
                    onRenderFooterContent={() => (
                        <DefaultButton
                            onClick={() => {
                                setIsConfigPanelOpen(false);
                            }}
                        >
                            Close
                        </DefaultButton>
                    )}
                    isFooterAtBottom={true}
                >
                    <Text> The chat history will be cleared when switching gpt models. </Text>
                    <GptModelSelector
                        className={styles.chatSettingsSeparator}
                        label="GPT Model"
                        selectedKey={selectedGptModel.name}
                        setSelectedKey={setSelectedGptModel}
                    />
                    <Text>{selectedGptModel.description}</Text>
                </Panel>
            </div>

            <ScrollButtons />
        </div>
    );
};

export default ChatGPT;

import { ReactNode } from "react";
import { AADAuthenticator } from "./azureAAD/Authenticator";

type Props = {
    children: ReactNode;
    idp: string;
};

export const Authenticator = ({ children, idp }: Props) => {
    if (idp === "azure_ad") {
        return <AADAuthenticator>{children}</AADAuthenticator>;
    } else {
        throw Error;
    }
};

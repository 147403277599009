import { HashRouter } from "react-router-dom";
import { useEffect, useState } from "react";

import "./index.css";
import { GlobalContext } from "./GlobalContext";
import { defaultScenarioName, getScenario, getHeaderTitle, getExampleList, getExampleQuestionPlaceholder, getBackendScenario } from "./content";
import { ExampleModel } from "./components/Example";

import { AppRoutes } from "./AppRoutes";
import { logoDict } from "./hooks/useQueryParamLogo";

function App() {
    const defaultScenario = defaultScenarioName;
    const [scenario, setScenario] = useState<string>(getScenario(defaultScenario));
    const [headerTitle, setHeaderTitle] = useState<string>(getHeaderTitle(defaultScenario));
    const [exampleList, setExampleList] = useState<ExampleModel[]>(getExampleList(defaultScenario));
    const [exampleQuestionPlaceholder, setExampleQuestionPlaceholder] = useState<string>(getExampleQuestionPlaceholder(defaultScenario));
    const [backendScenario, setBackendScenario] = useState<string>(getBackendScenario(defaultScenario));
    const [logo, setLogo] = useState<string>(logoDict["default"]);
    const [isDefaultLogo, setIsDefaultLogo] = useState<boolean>(true);

    // Set the default identity provider to be AWS Cognito
    const idpFromLocalStorage = localStorage.getItem("idp")?.toLowerCase();
    const idp = "azure_ad";

    useEffect(() => {
        if (idpFromLocalStorage === undefined) {
            localStorage.setItem("idp", "");
        }
    }, []);

    return (
        <GlobalContext.Provider
            value={{
                defaultScenario,
                scenario,
                setScenario,
                headerTitle,
                setHeaderTitle,
                exampleList,
                setExampleList,
                exampleQuestionPlaceholder,
                setExampleQuestionPlaceholder,
                backendScenario,
                setBackendScenario,
                logo,
                setLogo,
                isDefaultLogo,
                setIsDefaultLogo
            }}
        >
            <HashRouter>
                <AppRoutes idp={idp} />
            </HashRouter>
        </GlobalContext.Provider>
    );
}

export default App;

import { Stack } from "@fluentui/react";
import DOMPurify from "dompurify";

import styles from "./Answer.module.css";

import { AskResponse } from "../../api";
import Typewriter from "../../helper/typeWritter";

interface Props {
    answer: AskResponse;
}

export const AnswerChatGPT = ({ answer }: Props) => {
    const sanitizedAnswerHtml = DOMPurify.sanitize(answer.answer);

    return (
        <Stack className={`${styles.answerContainer}`} verticalAlign="space-between">
            <Stack.Item grow>
                <div className={styles.answerText}>
                    <Typewriter text={sanitizedAnswerHtml} delay={10} parseMarkdown={true} />
                </div>
            </Stack.Item>
        </Stack>
    );
};

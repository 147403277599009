import { AskResponse, ChatRequest, ChatGPTRequest } from "./models";
import { getAuthToken } from "../store/auth";
import { spScenarios } from "../content";
const backendURL = import.meta.env.VITE_API_URL;

export async function promptApi(options: ChatRequest): Promise<AskResponse> {
    const authToken = await getAuthToken(options.idp);

    const response = await fetch(`${backendURL}/prompt`, {
        method: "POST",
        headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            idp: options.idp,
            scenario: options.scenario,
            history: options.history,
            approach: options.approach,
            overrides: {
                persona: "default",
                temperature: 0.2,
                semantic_ranker: options.overrides?.semanticRanker,
                semantic_captions: options.overrides?.semanticCaptions,
                top: options.overrides?.top,
                prompt_template: options.overrides?.promptTemplate,
                prompt_template_prefix: options.overrides?.promptTemplatePrefix,
                prompt_template_suffix: options.overrides?.promptTemplateSuffix,
                exclude_category: options.overrides?.excludeCategory,
                suggest_followup_questions: options.overrides?.suggestFollowupQuestions,
                gpt_model: options.overrides?.gptmodel
            }
        })
    });

    try {
        const parsedResponse: AskResponse = await response.json();
        if (response.status > 299 || !response.ok) {
            // client side error
            // status 403 - Out of call volume quota
            if (parsedResponse.statusCode == 403) {
                throw Error(parsedResponse.message);
            }
            // status 429 - too many requests
            if (parsedResponse.statusCode == 429) {
                throw Error("Too Many Requests. " + parsedResponse.message);
            }
            // status 500 - over token limit
            if (parsedResponse.statusCode == 500) {
                throw Error(parsedResponse.message || "Open AI Service Error.");
            }
            // Server side error
            // server unavailable
            if (parsedResponse.statusCode == 503) {
                throw Error(parsedResponse.message ||  "Server Service Unavailable.");
            }
            // other unknown errors
            throw Error("Unknown Error.");
        }
        return parsedResponse;
    } catch (e) {
        console.log(e);
        if (response.status > 299 || !response.ok) {
            // client side error
            // status 403 - Out of call volume quota
            if (response.status == 403) {
                throw Error(response.statusText);
            }
            // status 429 - too many requests
            if (response.status == 429) {
                throw Error("Too Many Requests. " + response.statusText);
            }
            // status 500 - over token limit
            if (response.status == 500) {
                throw Error(response.statusText || "Open AI Service Error.");
            }
            // Server side error
            // server unavailable
            if (response.status == 503) {
                throw Error(response.statusText ||  "Server Service Unavailable.");
            }
            // other unknown errors
            throw Error("Unknown Error.");
        }
        throw Error("Server Service Error.");
    }
}


export async function chatGPTApi(options: ChatGPTRequest): Promise<AskResponse> {
    const authToken = await getAuthToken(options.idp);

    const response = await fetch(`${backendURL}/chatGPT`, {
        method: "POST",
        headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            idp: options.idp,
            history: options.history,
            overrides: {
                gpt_model: options.overrides?.gptmodel
            }
        })
    });

    try {
        const parsedResponse: AskResponse = await response.json();
        if (response.status > 299 || !response.ok) {
            // client side error
            // status 403 - Out of call volume quota
            if (parsedResponse.statusCode == 403) {
                throw Error(parsedResponse.message);
            }
            // status 429 - too many requests
            if (parsedResponse.statusCode == 429) {
                throw Error("Too Many Requests. " + parsedResponse.message);
            }
            // status 500 - over token limit
            if (parsedResponse.statusCode == 500) {
                throw Error(parsedResponse.message || "Open AI Service Error.");
            }
            // Server side error
            // server unavailable
            if (parsedResponse.statusCode == 503) {
                throw Error(parsedResponse.message ||  "Server Service Unavailable.");
            }
            // other unknown errors
            throw Error("Unknown Error.");
        }
        return parsedResponse;
    } catch (e) {
        console.log(e);
        if (response.status > 299 || !response.ok) {
            // client side error
            // status 403 - Out of call volume quota
            if (response.status == 403) {
                throw Error(response.statusText);
            }
            // status 429 - too many requests
            if (response.status == 429) {
                throw Error("Too Many Requests. " + response.statusText);
            }
            // status 500 - over token limit
            if (response.status == 500) {
                throw Error(response.statusText || "Open AI Service Error.");
            }
            // Server side error
            // server unavailable
            if (response.status == 503) {
                throw Error(response.statusText ||  "Server Service Unavailable.");
            }
            // other unknown errors
            throw Error("Unknown Error.");
        }
        throw Error("Server Service Error.");
    }
}

export function getCitationFilePath(scenario: string, citation: string, idp: string): string {
    if (spScenarios.includes(scenario)) return `${backendURL}/sp-content?filename=${citation}&scenario=${scenario}&idp=${idp}`;
    return `${backendURL}/content?filename=${citation}&scenario=${scenario}&idp=${idp}`;
}

export function getCitationUrlPath(citation: string, dataPoints: string[], docUrls: string[]): string {
    const index = dataPoints.findIndex(dataPoint => dataPoint.includes(citation));
    return docUrls[index];
}

// Helper function to set Authorization when calling GET request /content
export async function readCitationURL(activeCitation: string, idp: string, isSharePointSource?: boolean, openLinkInNewTab?: boolean) {
    const authToken = await getAuthToken(idp);
    let pdfBlob;
    const response = await fetch(activeCitation, {
        headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json"
        }
    });

    if (response.status > 299 || !response.ok) {
        throw Error("Error in retrieving citation data.");
    }

    if (isSharePointSource) {
        if (openLinkInNewTab) {
            const resp = await response.text();
            return resp;
        }
        const arrayBuffer = await response.arrayBuffer();
        pdfBlob = new Blob([new Uint8Array(arrayBuffer)], { type: "application/pdf" });
    } else {
        pdfBlob = await response.blob();
    }
    const pdfURL = URL.createObjectURL(pdfBlob);
    return pdfURL;
}

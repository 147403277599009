import { Outlet, NavLink, useNavigate, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { IDropdownOption } from "@fluentui/react";

import styles from "./Layout.module.css";

import { useGlobalContext } from "../../GlobalContext";
import { options, getExampleList, getHeaderTitle, getExampleQuestionPlaceholder, getBackendScenario } from "../../content";
import { ScenarioSelector } from "../../components/ScenarioSelector";
import { B2CSignoutButton, AADSignoutButton, CognitoSignoutButton } from "../../components/LogoutButton";
import { getRouteWithLogoParam, insertLogoParamIntoRoute } from "../../helper/getRouteWithLogoParam";

type Props = {
    idp: string;
};

const Layout = ({ idp }: Props) => {
    const [selectedOption, setSelectedOption] = useState<string | number>(options[0].key);
    const [showGPTFamily, setShowGPTFamily] = useState<boolean>(false);
    const [dropdownDisabled, setDropdownDisabled] = useState<boolean>(false);
    const { defaultScenario, setScenario, setHeaderTitle, setBackendScenario, setExampleList, setExampleQuestionPlaceholder, logo, isDefaultLogo } =
        useGlobalContext();
    const navigate = useNavigate();
    const location = useLocation();
    const textAIPlaceholder = isDefaultLogo ? "OpenAI" : "GenAI";

    // Redirect to the default use case scenario
    useEffect(() => {
        navigate(insertLogoParamIntoRoute(`/${defaultScenario}`, logo));
        setSelectedOption(defaultScenario);
        setContent(defaultScenario);
    }, []);

    // Reset configurable content depending on the selected scenario
    const handleDropdownChange = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
        if (option) {
            const scenario = option.key;
            navigate(getRouteWithLogoParam(`/${scenario}`, location));
            setSelectedOption(scenario);
            setContent(scenario.toString());
        }
    };

    const setContent = (scenario: string) => {
        setScenario(scenario);
        setHeaderTitle(getHeaderTitle(scenario));
        setExampleList(getExampleList(scenario));
        setExampleQuestionPlaceholder(getExampleQuestionPlaceholder(scenario));
        setBackendScenario(getBackendScenario(scenario));
    };

    const handleMouseEnter = () => {
        setShowGPTFamily(true);
    };

    const handleMouseLeave = () => {
        setShowGPTFamily(false);
    };

    const handleDropdownClick = (disabled: boolean) => {
        setDropdownDisabled(disabled);
    };
    return (
        <div className={styles.layout}>
            <header className={styles.header} role={"banner"}>
                <div className={styles.headerContainer}>
                    <div className={styles.headerLeftText}>
                        <div className={styles.brandLogoContainer}>
                            <img className={styles.brandLogo} src={logo} alt="Logo" aria-label="Logo" />
                        </div>
                        <div className={styles.brandLogoContainer}>
                            <h2 className={styles.headerTitle}>AI Copilot</h2>
                        </div>
                        <div className={styles.headerTitleContainer}>
                            <nav>
                                <ul className={styles.headerNavList}>
                                    <li>
                                        <NavLink
                                            to={getRouteWithLogoParam(`/${selectedOption}`, location)}
                                            onClick={() => handleDropdownClick(false)}
                                            className={({ isActive }) => (isActive ? styles.headerNavPageLinkActive : styles.headerNavPageLink)}
                                        >
                                            Document AI Search
                                        </NavLink>
                                    </li>
                                    <li>
                                        <div className={styles.headerNavDivider}></div>
                                    </li>
                                    <li className={styles.headerNavLeftMargin}>
                                        <NavLink
                                            to={getRouteWithLogoParam("/chatGpt", location)}
                                            onClick={() => handleDropdownClick(true)}
                                            className={({ isActive }) => (isActive ? styles.headerNavPageLinkActive : styles.headerNavPageLink)}
                                        >
                                            Private ChatGPT
                                        </NavLink>
                                    </li>
                                </ul>
                            </nav>
                            <div className={styles.headerScenarioSelector}>
                                <ScenarioSelector disabled={dropdownDisabled} options={options} selectedKey={selectedOption} onChange={handleDropdownChange} />
                            </div>
                        </div>
                    </div>
                    <div className={styles.headerRightText}>
                        {idp === "aws_cognito" && <CognitoSignoutButton />}
                        {idp === "azure_b2c" && <B2CSignoutButton />}
                        {idp === "azure_ad" && <AADSignoutButton />}
                    </div>
                </div>
            </header>
            <Outlet />
        </div>
    );
};

export default Layout;

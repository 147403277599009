import { Route, Routes } from "react-router-dom";
import Layout from "./pages/layout/Layout";
import ChatGPT from "./pages/chatGPT/ChatGPT";
import Chat from "./pages/chat/Chat";
import NoPage from "./pages/NoPage";
import { useQueryParamLogo } from "./hooks/useQueryParamLogo";
import { Authenticator } from "./components/Authenticator";

export const AppRoutes = ({ idp }: { idp: any }) => {
    useQueryParamLogo();

    return (
        <Authenticator idp={idp}>
            <Routes>
                <Route path="/" element={<Layout idp={idp} />}>
                    <Route path="/:scenario" index element={<Chat idp={idp} />} />
                    <Route path="chatGPT" element={<ChatGPT idp={idp} />} />
                    <Route path="*" element={<NoPage />} />
                </Route>
            </Routes>
        </Authenticator>
    );
};

import { ArrowUp16Regular, ArrowDown16Regular } from "@fluentui/react-icons";
import { useEffect, useState } from "react";

import styles from "./ScrollButtons.module.css";

interface Props {
    className?: string;
}

export const ScrollButtons = ({ className }: Props) => {
    const [showUpButton, setShowUpButton] = useState<boolean>(false);
    const [showDownButton, setShowDownButton] = useState<boolean>(false);

    useEffect(() => {
        const handleScrollButtonsVisibility = () => {
            // Get the scroll posiiton
            const scrollHeight = window.pageYOffset;
            // Get the height of the viewpoint
            const windowHeight = window.innerHeight;
            // Get the height of the entire document
            const documentHeight = document.body.scrollHeight;

            if (documentHeight < 600) {
                setShowUpButton(false);
                setShowDownButton(false);
            } else {
                if (scrollHeight === 0) {
                    // When users are at the top of the page
                    setShowUpButton(false);
                    setShowDownButton(true);
                } else if (scrollHeight + windowHeight > documentHeight - 1) {
                    // When users are at the bottom of the page
                    setShowUpButton(true);
                    setShowDownButton(false);
                } else {
                    // When uers are in the middle
                    setShowUpButton(true);
                    setShowDownButton(true);
                }
            }
        };

        window.addEventListener("scroll", handleScrollButtonsVisibility);

        return () => {
            window.removeEventListener("scroll", handleScrollButtonsVisibility);
        };
    });

    const handleScrollToTop = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    };

    const handleScrollToBottom = () => {
        window.scrollTo({ top: document.body.offsetHeight, behavior: "smooth" });
    };

    return (
        <div className={`${styles.container} ${className ?? ""}`}>
            <div className={`${showUpButton ? styles.button : styles.buttonDisabled}`} onClick={handleScrollToTop}>
                <ArrowUp16Regular className={`${showUpButton ? "" : styles.iconDisabled}`} />
            </div>

            <div className={`${showDownButton ? styles.button : styles.buttonDisabled}`} onClick={handleScrollToBottom}>
                <ArrowDown16Regular className={`${showDownButton ? "" : styles.iconDisabled}`} />
            </div>
        </div>
    );
};

import { Dropdown, IDropdownOption, DropdownMenuItemType } from "@fluentui/react";

import { gptModels } from "../../content";

import "./ConfigSelector.css";

interface Props {
    className?: string;
    label: string;
    selectedKey: string | undefined;
    setSelectedKey: ({ name, description }: { name: string | undefined; description: string | undefined }) => void;
}

export const GptModelSelector = ({ className, label, selectedKey, setSelectedKey }: Props) => {
    const dropdownOptions: IDropdownOption[] = [{ key: "header", text: "Select a Model", itemType: DropdownMenuItemType.Header }, ...gptModels];

    const handleDropdownChangeGptModel = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
        if (option) {
            const gptModel = gptModels.find(gptModel => gptModel.key === option.key);
            setSelectedKey({
                name: gptModel?.key,
                description: gptModel?.description
            });
        }
    };

    return (
        <div className={`gptmodelSelector ${className ?? ""}`}>
            <Dropdown label={label} options={dropdownOptions} selectedKey={selectedKey} onChange={handleDropdownChangeGptModel} />
        </div>
    );
};

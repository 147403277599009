import { useLocation, useNavigate } from "react-router-dom";
import { useAuthenticator as cognitoUseAuthenticator } from "@aws-amplify/ui-react";

import styles from "./LogoutButton.module.css";
import { getRouteWithLogoParam } from "../../helper/getRouteWithLogoParam";

export const CognitoSignoutButton = () => {
    const navigate = useNavigate();
    const { signOut } = cognitoUseAuthenticator(context => [context.user]);
    const location = useLocation();

    const onSignout = () => {
        try {
            signOut();
            navigate(getRouteWithLogoParam("/", location));
        } catch (e) {
            console.log(e);
        }
    };

    return (
        <button className={styles.signOutButton} onClick={onSignout}>
            LOG OUT
        </button>
    );
};

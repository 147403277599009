import styles from "./Button.module.css";

interface Props {
    name: string;
    onClick: (name: string) => void;
    selected: string;
}

export const Button = ({ name, onClick, selected }: Props) => {
    return (
        <button onClick={() => onClick(name)} className={`${styles.groupButton} ${name === selected ? `${styles.groupButtonChangeColor}` : ""}`}>
            {name}
        </button>
    );
};

import { renderToStaticMarkup } from "react-dom/server";
import { getCitationFilePath } from "../../api";
import { useGlobalContext } from "../../GlobalContext";

type HtmlParsedAnswer = {
    answerHtml: string;
    citations: string[];
    followupQuestions: string[];
};

export function parseAnswerToHtml(idp: string, answer: string, onCitationClicked: (citationFilePath: string) => void): HtmlParsedAnswer {
    const { backendScenario } = useGlobalContext();
    const citations: string[] = [];
    const followupQuestions: string[] = [];
    let fragments: string[] = [];
    if (answer) {
        // Extract any follow-up questions that might be in the answer
        let parsedAnswer = answer.replace(/<<([^>>]+)>>/g, (match, content) => {
            followupQuestions.push(content);
            return "";
        });

        // trim any whitespace from the end of the answer after removing follow-up questions
        parsedAnswer = parsedAnswer.trim();

        //the new citation delimiter style is <~my-sample-file-name.pdf~>
        const parts = parsedAnswer.split(/<~([^>]+)~>/g);

        fragments = parts.map((part, index) => {
            if (index % 2 === 0) {
                return part;
            } else {
                let citationIndex: number;
                if (citations.indexOf(part) !== -1) {
                    citationIndex = citations.indexOf(part) + 1;
                } else {
                    citations.push(part);
                    citationIndex = citations.length;
                }

                const path = getCitationFilePath(backendScenario, part, idp);

                return renderToStaticMarkup(
                    <a className="supContainer" title={part} onClick={() => onCitationClicked(path)}>
                        <sup>{citationIndex}</sup>
                    </a>
                );
            }
        });
    }

    return {
        answerHtml: fragments.join(""),
        citations,
        followupQuestions
    };
}

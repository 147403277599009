import { createContext, useContext } from "react";
import { ExampleModel } from "./components/Example";

export type GlobalContent = {
    defaultScenario: string;
    scenario: string;
    setScenario: (scenario: string) => void;
    headerTitle: string;
    setHeaderTitle: (heading: string) => void;
    exampleList: ExampleModel[];
    setExampleList: (exampleList: ExampleModel[]) => void;
    exampleQuestionPlaceholder: string;
    setExampleQuestionPlaceholder: (exampleQuestionPlaceholder: string) => void;
    backendScenario: string;
    setBackendScenario: (backendScenario: string) => void;
    logo: string;
    setLogo: (logo: string) => void;
    isDefaultLogo: boolean,
    setIsDefaultLogo: (isDefaultLogo: boolean) => void
};
export const GlobalContext = createContext<GlobalContent>({
    defaultScenario: "",
    scenario: "",
    setScenario: () => {},
    headerTitle: "",
    setHeaderTitle: () => {},
    exampleList: [{ text: "", value: "" }],
    setExampleList: () => {},
    exampleQuestionPlaceholder: "",
    setExampleQuestionPlaceholder: () => {},
    backendScenario: "",
    setBackendScenario: () => {},
    logo: "",
    setLogo: () => {},
    isDefaultLogo: true,
    setIsDefaultLogo: () => {},
});

export const useGlobalContext = () => useContext(GlobalContext);

import { LogLevel } from "@azure/msal-browser";
const redirectURI = import.meta.env.VITE_AUTH_REDIRECT_URI;

export const msalConfig = {
    auth: {
        clientId: import.meta.env.VITE_AZURE_AAD_APP_CLIENT_ID,
        authority: import.meta.env.VITE_AZURE_AAD_AUTHORITY,
        redirectUri: redirectURI
    },
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: false
    },
    system: {
        loggerOptions: {
            loggerCallback: (level: any, message: string, containsPii: boolean) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    default:
                        return;
                }
            }
        }
    }
};

export const apiConfig = {
    aadScopes: [
        `${import.meta.env.VITE_AZURE_AAD_APP_FUNCTION_SCOPE_URI}/${import.meta.env.VITE_AZURE_AAD_APP_READ_SCOPE}`,
        `${import.meta.env.VITE_AZURE_AAD_APP_FUNCTION_SCOPE_URI}/${import.meta.env.VITE_AZURE_AAD_APP_WRITE_SCOPE}`
    ]
};

export const tokenRequest = {
    scopes: [...apiConfig.aadScopes],
    forceRefresh: false // Set this to "true" to skip a cached token and go to the server to get a new token
};

/**
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest = {
    scopes: ["openid", "offline_access", ...apiConfig.aadScopes]
};

import { useRef, useState, useEffect } from "react";
import { Panel, DefaultButton, Text, Checkbox } from "@fluentui/react";
import styles from "./Chat.module.css";
import robot from "../../assets/saputo-robot-black.svg";
import { promptApi, Approaches, AskResponse, ChatRequest, ChatTurn } from "../../api";
import { Answer, AnswerError, AnswerLoading } from "../../components/Answer";
import { QuestionInput } from "../../components/QuestionInput";
import { ExampleList } from "../../components/Example";
import { UserChatMessage } from "../../components/UserChatMessage";
import { AnalysisPanel, AnalysisPanelTabs } from "../../components/AnalysisPanel";
import { SettingsButton } from "../../components/SettingsButton";
import { ClearChatButton } from "../../components/ClearChatButton";
import { useGlobalContext } from "../../GlobalContext";
import { PersonaSelector, TemperatureSelector } from "../../components/ConfigSelector";
import { gptModels, personas, temperatures } from "../../content";
import { ScrollButtons } from "../../components/ScrollButtons";
import { GptModelSelector } from "../../components/ConfigSelector/GptModelSelector";
import { getAuthToken } from "../../store/auth";
import { fetchEventSource } from "@microsoft/fetch-event-source";

type Props = {
    idp: string;
};

interface SelectedPersona {
    name: string | undefined;
    description: string | undefined;
}

interface SelectedTemperature {
    name: number | undefined;
    description: string | undefined;
}

interface selectedGptModel {
    name: string | undefined;
    description: string | undefined;
}

const Chat = ({ idp }: Props) => {
    const [isConfigPanelOpen, setIsConfigPanelOpen] = useState(false);

    const [useSemanticRanker, setUseSemanticRanker] = useState<boolean>(false);
    const [useSemanticCaptions, setUseSemanticCaptions] = useState<boolean>(false);
    const lastQuestionRef = useRef<string>("");
    const chatMessageStreamEnd = useRef<HTMLDivElement | null>(null);
    const retrieveCount = 20;
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<unknown>();
    const [activeCitation, setActiveCitation] = useState<string>();
    const [activeAnalysisPanelTab, setActiveAnalysisPanelTab] = useState<AnalysisPanelTabs | undefined>(undefined);

    const [selectedAnswer, setSelectedAnswer] = useState<number>(0);
    const [answers, setAnswers] = useState<[user: string, response: AskResponse][]>([]);

    const { scenario: selectedScenario, headerTitle, exampleQuestionPlaceholder, backendScenario } = useGlobalContext();

    const [selectedPersona, setSelectedPersona] = useState<SelectedPersona>({ name: personas[0].key, description: personas[0].description });

    const [selectedTemperature, setSelectedTemperature] = useState<SelectedTemperature>({
        name: temperatures[0].key,
        description: temperatures[0].description
    });

    const [selectedGptModel, setSelectedGptModel] = useState<selectedGptModel>({
        name: gptModels[0].key,
        description: gptModels[0].description
    });

    const onUseSemanticRankerChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        setUseSemanticRanker(!!checked);
    };

    const onUseSemanticCaptionsChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        setUseSemanticCaptions(!!checked);
    };

    // API request to get prompt and answer
    const makeApiRequest = async (question: string) => {
        const scenario = backendScenario.toString();
        lastQuestionRef.current = question;

        error && setError(undefined);
        setIsLoading(true);
        setActiveCitation(undefined);
        setActiveAnalysisPanelTab(undefined);

        try {
            // get prompt by the question that user is asking
            const history: ChatTurn[] = answers.map(a => ({ user: a[0], bot: a[1].answer }));
            const dataApiRequest: ChatRequest = {
                idp,
                scenario,
                history: [...history, { user: question, bot: undefined }],
                approach: Approaches.ReadRetrieveRead,
                overrides: {
                    persona: selectedPersona.name,
                    temperature: selectedTemperature.name,
                    top: retrieveCount,
                    //semanticRanker: useSemanticRanker,
                    //semanticCaptions: useSemanticCaptions,
                    gptmodel: selectedGptModel.name
                }
            };
            const data = await promptApi(dataApiRequest);
            const prompt = data.prompt;
            const authToken = await getAuthToken(idp);

            // get answer by chat completion api via prompt
            var tmpPromptResponse = "";
            await fetchEventSource(`${import.meta.env.VITE_API_URL}/chat`, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${authToken}`,
                    Accept: "text/event-stream",
                    "Content-type": "application/json"
                },
                body: JSON.stringify({
                    idp: idp,
                    approach: Approaches.ReadRetrieveRead,
                    prompt: prompt,
                    overrides: {
                        persona: "default",
                        temperature: 0.2,
                        top: retrieveCount,
                        gpt_model: selectedGptModel.name
                    }
                }),
                async onopen(res) {
                    //const parsedResponse: AskResponse = await res.json();
                    if (res.ok && res.status === 200) {
                        console.log("Connection made ", res);
                    } else if (res.status > 299 || !res.ok) {
                        setIsLoading(false);
                        // client side error
                        // status 403 - Out of call volume quota
                        if (res.status == 403) {
                            throw Error(res.statusText);
                        }
                        // status 429 - too many requests
                        if (res.status == 429) {
                            throw Error("Too Many Requests. " + res.statusText);
                        }
                        // status 500 - over token limit
                        if (res.status == 500) {
                            throw Error(res.statusText || "Open AI Service Error.");
                        }
                        // Server side error
                        // server unavailable
                        if (res.status == 503) {
                            throw Error(res.statusText || "Server Service Unavailable.");
                        }
                        // other unknown errors
                        throw Error("Unknown Error.");
                    }
                },
                onmessage(event) {
                    if (isLoading) setIsLoading(false);
                    console.log("Streaming output: ", event.data);
                    tmpPromptResponse += event.data;
                    const formattedAnswerResponse: AskResponse = {
                        answer: tmpPromptResponse,
                        prompt: data.prompt,
                        thoughts: data.thoughts,
                        data_points: data.data_points,
                        doc_urls: data.doc_urls
                    };
                    setAnswers([...answers, [question, formattedAnswerResponse]]); // overwrite the old data
                },
                onclose() {
                    console.log("Connection closed by the server");
                    if (!tmpPromptResponse) {
                        setError("The answer exceeds the model's maximum context length.");
                    }
                },
                onerror(err) {
                    console.log("There was an error from server:", err);
                    setError(err);
                }
            });
        } catch (e) {
            console.log("There was an error from server:", e);
            setError(e);
        } finally {
            setIsLoading(false);
        }
    };

    // Clear chat
    const clearChat = () => {
        lastQuestionRef.current = "";
        error && setError(undefined);
        setActiveCitation(undefined);
        setActiveAnalysisPanelTab(undefined);
        setAnswers([]);
    };

    // Clear chat page when switching scenarios, personas, or temperature
    useEffect(() => clearChat(), [selectedScenario, selectedPersona, selectedTemperature, selectedGptModel]);

    // Scroll to view the messages
    useEffect(() => chatMessageStreamEnd.current?.scrollIntoView({ behavior: "smooth" }), [isLoading]);

    // On example question clicked
    const onExampleClicked = (example: string) => {
        makeApiRequest(example);
    };

    // Open the citation file in the new tab
    const onOpenLink = (url: string) => {
        window.open(url, "_blank", "noreferrer");
    };

    // Open and close the analysis tab on the right side
    const onToggleTab = (tab: AnalysisPanelTabs, index: number) => {
        if (activeAnalysisPanelTab === tab && selectedAnswer === index) {
            setActiveAnalysisPanelTab(undefined);
        } else {
            setActiveAnalysisPanelTab(tab);
        }

        setSelectedAnswer(index);
    };

    return (
        <div className={styles.container}>
            <div className={styles.commandsContainer}>
                <ClearChatButton className={styles.commandButton} onClick={clearChat} disabled={!lastQuestionRef.current || isLoading} />
                <SettingsButton className={styles.commandButton} onClick={() => setIsConfigPanelOpen(!isConfigPanelOpen)} />
            </div>
            <div className={styles.chatRoot}>
                <div className={styles.chatContainer}>
                    {!lastQuestionRef.current ? (
                        <div className={styles.chatEmptyState}>
                            <div className={styles.chatEmptyStateContainer}>
                                <img src={robot} alt="Saputo robot" height="150px" />
                                <div className={styles.chatEmptyStateTitles}>
                                    <h1 className={styles.chatEmptyStateTitle}>Chat with your data</h1>
                                    <h2 className={styles.chatEmptyStateSubtitle}>Ask anything in:</h2>
                                    <h2 className={styles.chatEmptyStateScenario}>{headerTitle}</h2>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className={styles.chatMessageStream}>
                            {answers.map((answer, index) => (
                                <div key={index}>
                                    <UserChatMessage message={answer[0]} />
                                    <div className={styles.chatMessageGpt}>
                                        <Answer
                                            idp={idp}
                                            key={index}
                                            answer={answer[1]}
                                            isSelected={selectedAnswer === index && activeAnalysisPanelTab !== undefined}
                                            onCitationClicked={c => onOpenLink(c)}
                                            onSupportingContentClicked={() => onToggleTab(AnalysisPanelTabs.SupportingContentTab, index)}
                                            onFollowupQuestionClicked={q => makeApiRequest(q)}
                                        />
                                    </div>
                                </div>
                            ))}
                            {isLoading && (
                                <>
                                    <UserChatMessage message={lastQuestionRef.current} />
                                    <div className={styles.chatMessageGptMinWidth}>
                                        <AnswerLoading />
                                    </div>
                                </>
                            )}
                            {error ? (
                                <>
                                    <UserChatMessage message={lastQuestionRef.current} />
                                    <div className={styles.chatMessageGptMinWidth}>
                                        <AnswerError error={error.toString()} onRetry={() => makeApiRequest(lastQuestionRef.current)} />
                                    </div>
                                </>
                            ) : null}
                            <div ref={chatMessageStreamEnd} />
                        </div>
                    )}

                    <div className={styles.chatInput}>
                        <QuestionInput
                            clearOnSend
                            placeholder={exampleQuestionPlaceholder}
                            disabled={isLoading}
                            onSend={question => makeApiRequest(question)}
                        />
                    </div>
                </div>

                {answers.length > 0 && activeAnalysisPanelTab && (
                    <AnalysisPanel
                        className={styles.chatAnalysisPanel}
                        activeCitation={activeCitation}
                        onActiveTabChanged={x => onToggleTab(x, selectedAnswer)}
                        citationHeight="810px"
                        answer={answers[selectedAnswer][1]}
                        activeTab={activeAnalysisPanelTab}
                        idp={idp}
                        selectedScenario={backendScenario}
                    />
                )}

                <Panel
                    headerText="Configure answer generation"
                    isOpen={isConfigPanelOpen}
                    isBlocking={false}
                    onDismiss={() => {
                        setIsConfigPanelOpen(false);
                    }}
                    closeButtonAriaLabel="Close"
                    onRenderFooterContent={() => (
                        <DefaultButton
                            onClick={() => {
                                setIsConfigPanelOpen(false);
                            }}
                        >
                            Close
                        </DefaultButton>
                    )}
                    isFooterAtBottom={true}
                >
                    <Text> The chat history will be cleared when switching gpt models. </Text>
                    <GptModelSelector
                        className={styles.chatSettingsSeparator}
                        label="GPT Model"
                        selectedKey={selectedGptModel.name}
                        setSelectedKey={setSelectedGptModel}
                    />
                    <Text>{selectedGptModel.description}</Text>
                    {/* 
                    <Checkbox
                        className={styles.chatSettingsSeparator}
                        checked={useSemanticRanker}
                        label="Enable Semantic Search"
                        onChange={onUseSemanticRankerChange}
                    />
                    
                    <Checkbox
                        className={styles.chatSettingsSeparator}
                        checked={useSemanticCaptions}
                        label="Use query-contextual summaries instead of whole documents"
                        onChange={onUseSemanticCaptionsChange}
                        disabled={!useSemanticRanker}
                    /> */}
                </Panel>
            </div>

            <ScrollButtons />
        </div>
    );
};

export default Chat;

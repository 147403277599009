import { Dropdown, IDropdownOption, DropdownMenuItemType } from "@fluentui/react";

import "./ScenarioSelector.css";

interface DropdownProps {
    options: IDropdownOption[];
    selectedKey: string | number | undefined;
    disabled: boolean;
    onChange: (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => void;
}

export const ScenarioSelector = ({ options, selectedKey, disabled, onChange }: DropdownProps) => {
    const dropdownOptions: IDropdownOption[] = [{ key: "header", text: "Select a scenario", itemType: DropdownMenuItemType.Header }, ...options];

    return <Dropdown disabled={disabled} options={dropdownOptions} selectedKey={selectedKey} onChange={onChange} />;
};

import { msalInstance as msalInstanceAAD } from "../components/Authenticator/azureAAD/Authenticator";
import { tokenRequest as tokenRequestAAD } from "../components/Authenticator/azureAAD/authConfig";

// Function to get auth token from Azure AD
export async function getAadAuthToken() {
    const account = msalInstanceAAD.getAllAccounts()[0];

    const request = {
        scopes: tokenRequestAAD.scopes,
        account: account
    };

    try {
        const response = await msalInstanceAAD.acquireTokenSilent(request);
        let accessToken = response.accessToken;
        return accessToken;
    } catch (err) {
        if (err === "No current user") {
            throw err;
        }
    }
}

export async function getAuthToken(idp: string) {
    var authToken = null;
    if (idp === "azure_ad") {
        authToken = await getAadAuthToken();
    }

    return authToken;
}

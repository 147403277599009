import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { initializeIcons } from "@fluentui/react";

import "./index.css";

import "./assets/fonts/Sans-Regular.woff";
import "./assets/fonts/Sans-Bold.woff";

initializeIcons();

ReactDOM.createRoot(document.getElementById("root")!).render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);

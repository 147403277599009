import { useMsal } from "@azure/msal-react";

import styles from "./LogoutButton.module.css";

export const AADSignoutButton = () => {
    const { accounts, instance } = useMsal();
    const homeAccountId = accounts[0].homeAccountId;
    const logoutRequest = {
        account: instance.getAccountByHomeId(homeAccountId)
    };

    const onSignout = async () => {
        try {
            localStorage.setItem("idp", "");
            await instance.logoutRedirect(logoutRequest);
        } catch (e) {
            console.log(e);
        }
    };

    return (
        <button className={styles.signOutButton} onClick={onSignout}>
            LOG OUT
        </button>
    );
};

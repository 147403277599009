import { temperatures } from "../../content";
import { Button } from "./Button";
import { useState } from "react";

interface Props {
    setSelectedKey: ({ name, description }: { name: number | undefined; description: string | undefined }) => void;
}

export const ButtonGroup = ({ setSelectedKey }: Props) => {
    const [selected, setSelected] = useState("Balanced");

    const onClick = (name: string) => {
        setSelected(name);
        const temperature = temperatures.find(temperature => temperature.text === name);
        setSelectedKey({
            name: temperature?.key,
            description: temperature?.description
        });
    };

    return (
        <div className="container">
            <Button name="Creative" selected={selected} onClick={onClick} />
            <Button name="Balanced" selected={selected} onClick={onClick} />
            <Button name="Precise" selected={selected} onClick={onClick} />
        </div>
    );
};

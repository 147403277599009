import { Location } from "react-router-dom";
import { logoDict } from "../hooks/useQueryParamLogo";

export const getRouteWithLogoParam = (_route: string, location: Location) => {
    const params = new URLSearchParams(location.search);
    const logo: string | null = params.get("logo");
    const route = logo ? `${_route}?logo=${logo}` : _route;
    return route;
};

export const insertLogoParamIntoRoute = (_route: string, logo: string) => {
    // Keys from logoDict are valid values as a query param
    const logoKey = (Object.keys(logoDict) as Array<string>).find(key => logoDict[key] === logo);

    const route = logoKey && logoKey !== "default" ? `${_route}?logo=${logoKey}` : _route;
    return route;
};

import React, { useState, useEffect } from "react";
import Markdown from "react-markdown";
import ReactDOMServer from "react-dom/server";
import styles from "./../components/Answer/Answer.module.css";
interface Props {
    text: string;
    delay?: number;
    parseMarkdown?: boolean;
}

export const Typewriter = ({ text, delay, parseMarkdown }: Props) => {
    const [currentText, setCurrentText] = useState("");
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        if (currentIndex < text?.length) {
            const timeout = setTimeout(() => {
                setCurrentText(prevText => prevText + text[currentIndex]);
                setCurrentIndex(prevIndex => prevIndex + 1);
            }, delay);

            return () => clearTimeout(timeout);
        }
    }, [currentIndex, delay, text]);
    if (parseMarkdown) {
        return <Markdown>{currentText}</Markdown>;
    }
    const stringifiedMarkdownElements = ReactDOMServer.renderToString(<Markdown>{currentText}</Markdown>);
    return <div className={styles.answerText} dangerouslySetInnerHTML={{ __html: currentText }}></div>;
};

export default Typewriter;
